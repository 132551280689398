import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import SEO from '../components/SEO/SEO';
import Layout from '../components/shared/layout';
import { wrapper, button, breakpoints, border, color } from '../utils/style';
import error from '../img/error.png';

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  text-align: center;
  border-radius: ${border.borderRadius4};
  h1 {
    height: 104px;
    font-family: Pridi;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.44;
    text-align: center;
    color: white;
    @media (max-width: ${breakpoints.md}) {
      margin: 0;
      font-size: 32px;
    }
  }
  p {
    height: 52px;
    font-family: Prompt;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 100%;

    @media (max-width: ${breakpoints.md}) {
      height: auto;
      font-size: 12px;
      padding: 0 51px;
    }
  }
  a {
    ${button};
    width: 320px;

    @media (max-width: ${breakpoints.md}) {
      width: 220px;
    }
    height: 64px;
    object-fit: contain;
    background-color: ${color.dodgerBlue};
    margin: 39px 0;
    font-family: Prompt;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 0;
    padding-top: 23px;
  }
  @media (max-width: ${breakpoints.tl}) {
    padding: 40px 10px;
  }
`;

const NotFoundPage = () => {
  useEffect(() => {
    if (window) {
      const path = window.location.pathname.slice(1).substr(0, window.location.pathname.slice(1).indexOf('/'));
      const deletedIndexedPage = ['SBOBET-blog', 'refer-friend'];

      if (deletedIndexedPage.some((el) => el === path)) {
        (window.location.href = 'https://s-bobet.com/');
      }
    }
  }, []);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Wrapper>
        <img src={error} alt="error" />
        <h1>
          โอ็บส์! <br />
          เพจเข้าไม่ได้
        </h1>
        <p>
          พวกเราต้องขออภัยในความไม่สะดวก คุณอาจจะต้องพยายามเข้าใหม่อีกครั้ง เพราะลิ๊งนั้นๆอาจจะถูกลบหรือไม่เคยมีจริง
        </p>
        <Link to="/">ไปที่หน้าหลัก</Link>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
